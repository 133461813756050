import React from 'react';

import { Colors } from '@styles/variables';

const DevBanner = () => {
  if (!window.location.hostname.includes('localhost') && !window.location.hostname.includes('staging')) {
    return;
  }

  const color1 = window.location.hostname.includes('localhost') ? Colors.Magenta[1000] : Colors.Blue[900];
  const color2 = window.location.hostname.includes('localhost') ? Colors.Magenta.primary : Colors.Blue.primary;

  return (
    <div
      style={{
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: Colors.Magenta.primary,
        background: `repeating-linear-gradient(-45deg,${color1},${color1} 10px,${color2} 10px,${color2} 20px)`,
        height: 18,
        zIndex: 9999,
        gap: 24,
        color: Colors.Black.primary,
        fontSize: 10,
        fontWeight: '900',
        overflow: 'hidden',
      }}
    />
  );
};

export default DevBanner;
